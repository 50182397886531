/* eslint-disable */
$(function() {

	/*Открытие форма*/

	$('.js-open-popup').on('click', function(event) {
		
		var popup = $(this).data("popup");

		$(".js-popup[data-popup="+popup+"]").addClass("is-open");
		return false;

	});

	/*Закрытие форм*/

	$('.js-close-popup').on('click', function(event) {
		$(".js-popup").removeClass("is-open");
	});


	/*Спойлер*/

	$(".js-spoiler-top").on('click', function(event) {
		if ($(this).hasClass("is-active")) {
			$(this).removeClass("is-active");
			$(this).next().slideUp(function() {
			});
		} else {
			$(".js-spoiler-top").removeClass("is-active");
			$(".js-spoiler-body").slideUp(function() {
			});
			$(this).addClass("is-active");
			$(this).next().slideDown(function() {});
		}
	});

	$(".js-search-btn").on('click', function(event) {
		$(".js-search").toggleClass("is-open");
	});


	$(".js-gamburger").on('click', function(event) {
		$(".js-menu").addClass("is-open");
	});

	$(".js-close-menu").on('click', function(event) {
		$(".js-menu").removeClass("is-open");
	});


	$(".js-m-catalog-open").on('click', function(event) {
		$(this).toggleClass("is-open");
		$(".js-mobile-catalog").toggleClass("is-open");
	});


	$(".js-tab").on('click', function(event) {

		var tab = $(this).data("tab");
		console.log(tab);

		$(".js-tab").removeClass("is-active");
		$(this).addClass("is-active");

	
		$(".js-tab-block").removeClass("is-active");
		$(".js-tab-block[data-tab="+tab+"]").addClass("is-active");
	});

	if ($(window).width() < 992) {
		$(".js-menu").swipe( {
			swipeLeft:function(event, direction, distance, duration, fingerCount) {
			  $(this).removeClass("is-open");
			 
			}, threshold:75

		});
	}


	$(".js-slider-range").slider({
		range: true,
		min: 0,
		max: 300,
		values: [ 40, 100 ],
		slide: function( event, ui ) {
			$(".js-min").val(ui.values[0]);
			$(".js-max").val(ui.values[1]);
		}
	});

	$(".js-min").val($( ".js-slider-range" ).slider( "values", 0 ));
	$(".js-max").val($( ".js-slider-range" ).slider( "values", 1 ));

	$(".js-slider-range1").slider({
		range: true,
		min: 0,
		max: 300,
		values: [ 40, 100 ],
		slide: function( event, ui ) {
			$(".js-min").val(ui.values[0]);
			$(".js-max").val(ui.values[1]);
		}
	});
	if ($(window).width() < 1200) {
		$(".js-menu-level").on('click', function(event) {
			$(this).toggleClass("is-open");
		});
	}

	$(".js-open-filter").on('click', function(event) {
		$(".js-filter").addClass("is-open");
	});

	$(".js-min1").val($( ".js-slider-range1" ).slider( "values", 0 ));
	$(".js-max1").val($( ".js-slider-range1" ).slider( "values", 1 ));

	$(".js-card-for").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.js-card-nav'
	  });

	  $('.js-card-nav').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: '.js-card-for',
		centerMode: true,
		focusOnSelect: true,
		vertical: true,
	  });

	$(".js-associated").slick({
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		prevArrow: '<button type="button" class="slick-prev"><img src="../images/common/prev.png"></button>',
		nextArrow: '<button type="button" class="slick-next"><img src="../images/common/next.png"></button>'
	  });
/*
	  if ($(window).width() < 768) {
		$(".js-associated").slick({
			infinite: true,
			slidesToShow: 2,
			slidesToScroll: 1,
			prevArrow: '<button type="button" class="slick-prev"><img src="../images/common/prev.png"></button>',
			nextArrow: '<button type="button" class="slick-next"><img src="../images/common/next.png"></button>'
		  });
	
	  }*/


	if ($('#map').length) {
		var init = function init() {
			var myMap = new ymaps.Map("map", {
				center: [47.258496, 38.925362],
				zoom: 16,
				controls: []
			});
	
			var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {

			}, {
				// Опции.
				// Необходимо указать данный тип макета.
				iconLayout: 'default#image',
				// Своё изображение иконки метки.
				iconImageHref: 'images/common/marker.svg',
				// Размеры метки.
				iconImageSize: [50, 61],
				// Смещение левого верхнего угла иконки относительно
				// её "ножки" (точки привязки).
				iconImageOffset: [-25, -108]
			});
	
			myMap.geoObjects.add(myPlacemark);
		};
	
		ymaps.ready(init);
	}
	
});